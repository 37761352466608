import IonIcon from '@reacticons/ionicons'
import React from 'react'

import { PremiumContentCategory } from './PremiumContentCategory'

export interface PremiumCategoriesProps {
  isVendor: boolean
}
export const PremiumContentCategories: React.FC<PremiumCategoriesProps> = ({ isVendor }: { isVendor: boolean }) => {
  return (
    <div className="w-full large-lg:px-0 py-[2.5rem] bg-warmBlue items-center flex">
      <div
        className={`grid !max-w-[1075px]  m-auto gap-[40px] grid-cols-1 ${isVendor ? ' lg:grid-cols-4' : 'threeBox lg:grid-cols-3'}`}
      >
        <PremiumContentCategory
          icon={<IonIcon name="compass-outline" className="text-[2.4rem] text-navybright" />}
          title="Solution Guides"
          description="Resources by our experts on each of our premium categories, landscapes, functional requirements, evaluation criteria and much more"
          url="/solution-guides/"
          isVendor={isVendor}
        />
        <PremiumContentCategory
          icon={<IonIcon name="information" className="text-[2.4rem] text-navybright" />}
          title="Know-How"
          description="Know how about digital transformation, knowledge management and innovation in law firms and 
          in-house legal departments"
          url="/know-how/"
          isVendor={isVendor}
        />
        <PremiumContentCategory
          icon={<IonIcon name="person-circle-outline" className="text-[2.4rem] text-navybright" />}
          title="Resources for Lawyers"
          description="Resources to increase lawyer fluency on key legaltech topics such as AI, automation and data."
          url="/legaltech-resources-for-lawyers/"
          isVendor={isVendor}
        />
        {isVendor && (
          <PremiumContentCategory
            icon={<IonIcon name="person-circle-outline" className="text-[2.4rem] text-navybright" />}
            title="Resources for Solution Providers (Beta)"
            description="Helpful resources on gotomarket, marketing, fund raising, templates, events, security."
            url="/resources-for-solution-providers/"
            isVendor={isVendor}
          />
        )}
      </div>
    </div>
  )
}
